<!--
 * @Description: 商品详情页面组件
 * @Author: hai-27
 * @Date: 2020-02-16 20:20:26
 * @LastEditors: hai-27
 * @LastEditTime: 2020-03-07 21:59:26
 -->
<template>
  <div id="details">
    <!-- 头部 -->
    <div class="page-header">
      <div class="title">
        <p>{{ productDetails.productName }}</p>
        <div class="list">
          <ul>
            <li @click="drawer = true">
              <router-link to>商品详情</router-link>
            </li>
            <li>
              <router-link to>用户评价</router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 头部END -->

    <!-- 主要内容 -->
    <div class="main" style="display: flex;">
      <!-- 左侧商品轮播图 -->
      <div class="block">
        <div>
          <img
              style="height:400px;width: 550px"
              :src="$target + productDetails.productPicture"
              alt=""
          />
        </div>
      </div>
      <!-- 左侧商品轮播图END -->

      <!-- 右侧内容区 -->
      <div class="content">
        <h1 class="name">{{ productDetails.productName }}</h1>
        <p class="intro">{{ productDetails.productIntro }}</p>
        <p class="store">{{ productDetails.productTitle }}</p>
        <div class="price">
          <span>{{ productDetails.productSellingPrice }}元</span>
          <span
              v-show="productDetails.productPrice != productDetails.productSellingPrice"
              class="del"
          >{{ productDetails.productPrice }}元</span>
        </div>
        <div class="pro-list">
          <span class="pro-name">{{ productDetails.productName }}</span>
          <span class="pro-price">
            <span>{{ productDetails.productSellingPrice }}元</span>
            <span
                v-show="productDetails.productPrice != productDetails.productSellingPrice"
                class="pro-del"
            >{{ productDetails.productPrice }}元</span>
          </span>
          <p class="price-sum">总计 : {{ productDetails.productSellingPrice }}元</p>
        </div>
        <!-- 内容区底部按钮 -->
        <div class="button">
          <el-button class="shop-cart" :disabled="dis" @click="addShoppingCart">立即购买</el-button>
          <el-button class="like" :disabled="dis" @click="addCollect">加入购物车</el-button>
          <!--          <el-button class="like" @click="addCollect">喜欢</el-button>-->
        </div>
        <!-- 内容区底部按钮END -->
        <div class="pro-policy">
          <ul>
            <li>
              <i class="el-icon-circle-check"></i> 保健品
            </li>
            <li>
              <i class="el-icon-circle-check"></i> 7天无理由退货
            </li>
            <li>
              <i class="el-icon-circle-check"></i> 7天价格保护
            </li>
          </ul>
        </div>
      </div>
      <!-- 右侧内容区END -->
    </div>
    <!-- 主要内容END -->
    <div class="content2">
      <h3>知情同意书：</h3>
      <p>
        尊敬的顾客。 </p>
      <p>在您决定购买并使用我们提供的保健品之前，我们希望您能够充分了解以下信息，并在完全理解的基础上做出明智的决定。</p>
      <p>
        1.使用指导：请严格按照产品说明书进行操作，如有疑问，请及时联系我们的客服。</p>
      <p>
        2.法律声明：购买本保健品即表示您已阅读并同意上述所有条款。如有任何争议，将按照相关法律法规处理。</p>
      <p>3.隐私保护：我们承诺保护您的个人信息安全，不会泄露给任何第三方。</p>

      <h3>退款相关：</h3>

      <p>
        1.退款条件：本产品自购买之日起7天内，如未拆封且不影响二次销售，可申请全额退款。</p>
      <p>
        2.退款说明：如购买后，在产品使用过程中，出现质量问题，可申请退款，退款金额按照实际支付金额计算。</p>
      <p>
        3.退款时间：在产品使用过程中，如出现质量问题，请及时联系我们，我们会在收到您的退货后，在7个工作日内，将商品原路返还。</p>

      <h3>价格说明</h3>
      <h4>划线价格</h4>
      <p>指商品的专柜价、吊牌价、正品零售价、厂商指导价或该商品的曾经展示过的销售价等，并非原价，仅供参考。</p>
      <h4>未划线价格</h4>

      <p>
        指商品的实时标价，不因表述的差异改变性质。具体成交价格根据商品参加活动，或会员使用优惠券、积分等发生变化，最终以订单结算页价格为准。</p>

      <p>商品详情页（含主图）以图片或文字形式标注的一口价、促销价、优惠价等价格可能是在使用优惠券、满减或特定优惠活动和时段等情形下的价格，具体请以结算页面的标价、优惠条件或活动规则为准。

        若商家单独对划线价格进行说明的，以商家的表述为准。</p>

      <h3>内容申明：</h3>
      <p>
        作为为第三方交易平台及互联网信息服务提供者，所展示的商品/服务的标题、价格、详情等信息内容系由店铺经营者发布，其真实性、准确性和合法性
        均由店铺经营者负责。提醒用户购买商品/服务前注意谨慎核实。如用户对商品/服务的标题、价格、详情等任何信息有任何疑问的，
        请在购买前通过客服电话：400-620-1800沟通确认；如用户发现店铺内有任何违法/侵权信息，请立即向平台举报并提供有效线索。
      </p>
    </div>
    <el-drawer
        size="auto"
        title="商品详情"
        :visible.sync="drawer" style="text-align: center;">
      <div style="width:800px;height: calc(100% - 4px)">
        <img v-for="(item,index) in detailPicture" :key="index" :src="$target + item" alt=""/>
      </div>
    </el-drawer>
  </div>
</template>
<script>
import { mapActions } from 'vuex'

export default {
  data () {
    return {
      drawer: false,
      dis: false, // 控制“加入购物车按钮是否可用”
      productID: '', // 商品id
      productDetails: {
        productName: '',
        productTitle: '',
        productPicture: '',
        productSellingPrice: '',
        productPrice: ''
      }, // 商品详细信息
      detailPicture: [] // 商品图片
    }
  },
  // 通过路由获取商品id
  activated () {
    if (this.$route.query.productDetail != undefined) {
      let detail = JSON.parse(this.$route.query.productDetail)
      this.productID = detail.productID
      this.productDetails.productName = detail.productName
      this.productDetails.productTitle = detail.productTitle
      this.productDetails.productPicture = detail.productPicture
      this.productDetails.productSellingPrice = detail.productSellingPrice
      this.productDetails.productPrice = detail.productPrice
      this.detailPicture.length = 0
      this.detailPicture = detail.detailPicture
    }
  },
  watch: {
    // 监听商品id的变化，请求后端获取商品数据
    productID: function (val) {
      this.getDetails(val)
      this.getDetailsPicture(val)
    }
  },
  methods: {
    ...mapActions(['unshiftShoppingCart', 'addShoppingCartNum']),
    // 获取商品详细信息
    getDetails (val) {
      this.$axios
          .get('/api/product/' + val)
          .then(res => {
            this.productDetails = res.data.data
          })
          .catch(err => {
            return Promise.reject(err)
          })
    },
    // 获取商品图片
    getDetailsPicture (val) {
      this.$axios
          .get('/api/productPicture/product/' + val)
          .then(res => {
            this.productPicture = res.data.data
          })
          .catch(err => {
            return Promise.reject(err)
          })
    },
    // 加入购物车
    addShoppingCart () {
      this.notifyInfo('等待微信h5开通')
      // 判断是否登录,没有登录则显示登录组件
      // if (!this.$store.getters.getUser) {
      //   this.$store.dispatch('setShowLogin', true)
      //   return
      // }
      // this.$axios
      //     .post('/api/cart/product/user/' + this.productID + '/' + this.$store.getters.getUser.userId)
      //     .then(res => {
      //       switch (res.data.code) {
      //         case '001':
      //           // 新加入购物车成功
      //           this.unshiftShoppingCart(res.data.data)
      //           this.notifySucceed(res.data.msg)
      //           break
      //         case '002':
      //           // 该商品已经在购物车，数量+1
      //           this.addShoppingCartNum(this.productID)
      //           this.notifySucceed(res.data.msg)
      //           break
      //         case '003':
      //           // 商品数量达到限购数量
      //           this.dis = true
      //           this.notifyError(res.data.msg)
      //           break
      //         default:
      //           this.notifyError(res.data.msg)
      //       }
      //     })
      //     .catch(err => {
      //       return Promise.reject(err)
      //     })
    },
    addCollect () {
      this.notifyInfo('等待功能开放')
      // // 判断是否登录,没有登录则显示登录组件
      // if (!this.$store.getters.getUser) {
      //   this.$store.dispatch('setShowLogin', true)
      //   return
      // }
      // this.$axios
      //     .post('/api/collect/user/' + this.productID + '/' + this.$store.getters.getUser.userId)
      //     .then(res => {
      //       if (res.data.code == '001') {
      //         // 添加收藏成功
      //         this.notifySucceed(res.data.msg)
      //       } else {
      //         // 添加收藏失败
      //         this.notifyError(res.data.msg)
      //       }
      //     })
      //     .catch(err => {
      //       return Promise.reject(err)
      //     })
    }
  }
}
</script>
<style>
h3 {
  margin: 20px 0;
}

/* 头部CSS */
#details .page-header {
  height: 64px;
  margin-top: -20px;
  z-index: 4;
  background: #fff;
  border-bottom: 1px solid #e0e0e0;
  -webkit-box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.07);
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.07);
}

#details .page-header .title {
  width: 1225px;
  height: 64px;
  line-height: 64px;
  font-size: 18px;
  font-weight: 400;
  color: #212121;
  margin: 0 auto;
}

#details .page-header .title p {
  float: left;
}

#details .page-header .title .list {
  height: 64px;
  float: right;
}

#details .page-header .title .list li {
  float: left;
  margin-left: 20px;
}

#details .page-header .title .list li a {
  font-size: 14px;
  color: #616161;
}

#details .page-header .title .list li a:hover {
  font-size: 14px;
  color: #ff6700;
}

/* 头部CSS END */

/* 主要内容CSS */
#details .main {
  width: 1225px;
  height: 450px;
  padding-top: 30px;
  margin: 0 auto;
}

#details .content2 {
  width: 1225px;
  padding-top: 30px;
  margin: 0 auto;
}

#details .main .block {
  float: left;
  flex: 1;
}

#details .el-carousel .el-carousel__indicator .el-carousel__button {
  background-color: rgba(163, 163, 163, 0.8);
}

#details .main .content {
  float: left;
  margin-left: 25px;
  width: 640px;
}

#details .main .content .name {
  font-size: 24px;
  font-weight: normal;
  color: #212121;
}

#details .main .content .intro {
  color: #b0b0b0;
  padding-top: 10px;
}

#details .main .content .store {
  color: #ff6700;
  padding-top: 10px;
}

#details .main .content .price {
  display: block;
  font-size: 18px;
  color: #ff6700;
  border-bottom: 1px solid #e0e0e0;
  padding: 25px 0 25px;
}

#details .main .content .price .del {
  font-size: 14px;
  margin-left: 10px;
  color: #b0b0b0;
  text-decoration: line-through;
}

#details .main .content .pro-list {
  background: #f9f9fa;
  padding: 20px 30px;
  margin: 20px 0 20px;
}

#details .main .content .pro-list span {
  line-height: 30px;
  color: #616161;
}

#details .main .content .pro-list .pro-price {
  float: right;
}

#details .main .content .pro-list .pro-price .pro-del {
  margin-left: 10px;
  text-decoration: line-through;
}

#details .main .content .pro-list .price-sum {
  color: #ff6700;
  font-size: 24px;
  padding-top: 20px;
}

#details .main .content .button {
  height: 55px;
  margin: 10px 0 20px 0;
  display: flex;
}

#details .main .content .button .el-button {
  float: left;
  height: 55px;
  font-size: 16px;
  color: #fff;
  border: none;
  text-align: center;
}

#details .main .content .button .shop-cart {
  width: 200px;
  background-color: #ff6700;
}

#details .main .content .button .shop-cart:hover {
  background-color: #f25807;
}

#details .main .content .button .like {
  width: 200px;
  margin-left: 40px;
  background-color: #b0b0b0;
}

#details .main .content .button .like:hover {
  background-color: #757575;
}

#details .main .content .pro-policy li {
  float: left;
  margin-right: 20px;
  color: #b0b0b0;
}


/* 主要内容CSS END */
</style>